import { css } from 'styled-components';

export const ValignTextMiddle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ValignTextBottom = css`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const LatoNormalChathamsBlue20px = css`
  color: var(--chathams-blue);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const NunitoSemiBoldChathamsBlue25px = css`
  color: var(--chathams-blue);
  font-family: var(--font-family-nunito);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
`;

export const NunitoNormalChathamsBlue25px = css`
  color: var(--chathams-blue);
  font-family: var(--font-family-nunito);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const LatoNormalBlack20px = css`
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const NunitoBoldChathamsBlue25px = css`
  color: var(--chathams-blue);
  font-family: var(--font-family-nunito);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`;

export const LatoNormalBlackHaze20px = css`
  color: var(--black-haze);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xs);
  font-weight: 400;
  font-style: normal;
`;

export const LatoBoldBlack35px = css`
  color: var(--black);
  font-family: var(--font-family-lato);
  font-size: 35px;
  font-weight: 700;
  font-style: normal;
`;

export const LatoLightBlack30px = css`
  color: var(--black-2);
  font-family: var(--font-family-lato);
  font-size: 24px;
  font-weight: 300;
  font-style: normal;
`;

export const LatoBlackWhite55px = css`
  color: var(--white);
  font-family: var(--font-family-lato);
  font-size: var(--font-size-xxl);
  font-weight: 900;
  font-style: normal;
`;
