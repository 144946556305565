import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocalization } from 'gatsby-theme-i18n';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Container,
  Row,
  Col
} from 'reactstrap'
import {
  LatoBoldBlack35px,
  LatoNormalBlackHaze20px,
  LatoLightBlack30px,
  LatoNormalBlack20px,
  LatoBlackWhite55px,
  NunitoSemiBoldChathamsBlue25px,
  NunitoNormalChathamsBlue25px,
  ValignTextBottom,
  LatoNormalChathamsBlue20px,
  ValignTextMiddle,
} from '../../anima/styledMixins';
import '../../anima/globals.css';
import '../../anima/styleguide.css';
import CertificateIcon from '../images/certificate-icon.svg';
import FileIcon from '../images/file-icon.svg';
import PageIcon from '../images/page-icon.svg';
import ContractIcon from '../images/contract-icon.svg';
import SchoolIcon from '../images/school-icon.svg';
import Layout from '../layouts/index';
import DemoForm from '../components/DemoForm';
import Slider from '../components/Slider';
const desktop1Data = {
  image2: '/img/image-2@2x.png',
  image4: '/img/image-4@2x.png',
  text1: '¡Únete a Oyster y obtén los primeros 4 meses gratis del Plan Básico o Premium!',
  spanText: <>TRATO y Oyster han creado una alianza para apoyar la digitalización de los procesos jurídicos de tu empresa.<br /><br />Para recibir este beneficio, sólo debes unirte a Oyster y recibe </>,
  spanText2: 'SIN COSTO',
  spanText3: ' los primeros 4 meses del plan ',
  spanText4: 'PREMIUM ',
  spanText5: 'al registrate como persona moral.',
  spanText6: 'Si aún no tienes una empresa debido a que eres una ',
  spanText7: 'persona física',
  spanText8: ' con actividad empresarial, también puedes obtener los primeros ',
  spanText9: '4',
  spanText10: ' meses gratis del plan ',
  spanText11: 'Básico',
  spanText12: ' al unirte a Oyster.',
  text4: '¡Regístrate  para obtener 4 meses gratis!',
  registrarse: 'Registrarse',
  text5: 'Mantén tus procesos en un mismo lugar con la seguridad y agilidad que necesitas con alguno de nuestros planes:',
  freemium: 'Freemium',
  price: '$0',
  x25MbAlmacenamiento: '25MB Almacenamiento',
  repositorio: 'Repositorio',
  address: '10 Firmas blindaje 1',
  address2: '1 Plantilla gratuita',
  bsico: 'Básico',
  bsico2: 'Básico',
  text6: <><br />Con solo pagar $99 pesos al mes podrás tener acceso a:<br /><br />Las plantillas más utilizadas en los negocios que apenas empiezan.<br /><br />5 firmas electrónicas al mes.<br /><br />Sistema básico de gestión donde podrás cambiar correo en un contrato enviado.<br /><br />Ver el correo del participante<br /><br />Cancelar contrato<br /><br />Eliminar documentos en borrador.</>,
  premium: 'Premium',
  text7: <><br />Por $899 pesos al mes tendrás <br />acceso a:<br /><br />Todas las plantillas basicas a tu disposicion.<br /><br />Una plantilla premium por mes<br /><br />10 firmas electrónicas al mes.<br /><br />Sistema básico de gestión donde podrás cambiar correo en un contrato enviado.<br /><br />Ver el correo del participante<br /><br />Cancelar contrato<br /><br />Eliminar documentos en borrador.<br /><br />Edicion ilimitada de plantillas</>,
  x50GbAlmacenamiento: '50GB Almacenamiento',
  text8: 'Gestion basica de envio',
  address3: '10 Firmas blindaje 1',
  address4: '5 Firmas blindaje 2',
  text9: 'Acceso a plantillas basicas',
  premium2: 'Premium',
  text10: '500GB Almacenamiento',
  text11: 'Gestion basica de envio',
  address5: '50 Firmas blindaje 1',
  address6: '10 Firmas blindaje 2',
  text12: 'Acceso a plantillas basicas',
  address7: '1 plantilla premium',
  text13: 'Edicion ilimitada de plantillas',
  text14: 'El pequeño paso que te ayudará a sobresalir de los demás en facilidades de negociar.',
  text15: 'Cuando comienzas a crecer y necesitas que alguien te ayude a no distraerte de lo que te gusta hacer.',
  name: 'Dale ese trato especial a cada uno de tus clientes, para que vuelvan una y otra vez.',
  price2: '$99',
  price3: '$899',
  text16: 'Descripción de planes',
  text17: '¿Por qué firmar de manera electrónica?',
  text18: <><br />Disponibilidad inmediata tanto online como offline.<br /><br />Reducción de costos operativos y legales.<br /><br />Más elementos de seguridad que un contrato convencional.<br /><br />Aporte de elementos robustos para la valoración y comprobación de derechos y obligaciones por parte de las autoridades correspondientes.<br /><br />Fácil recuperación y disponibilidad inmediata para su consulta.</>,
  image3: '/img/image-3@1x.png',
  text19: '¿Por qué confiar en nosotros?',
  text20: <>TRATO y Oyster se comprometen a acompañar a tu negocio en su proceso de transformación digital.<br /><br />Ofrecemos diversos blindajes de firmas que cuentan con los elementos jurídicos necesarios para hacer frente a cualquier situación generada con el contrato digital.</>,
  text21: 'Algunas empresas que protegen sus contratos con nosotros.',
  pngegg11: '/img/pngegg--1--1@2x.png',
  image7: '/img/image-7@2x.png',
  pngegg21: '/img/pngegg--2--1@2x.png',
  pngegg31: '/img/pngegg--3--1@2x.png',
};


function OysterLanding({ location, ...props }) {
  const {
    image2,
    image4,
    text1,
    spanText,
    spanText2,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    spanText8,
    spanText9,
    spanText10,
    spanText11,
    spanText12,
    text4,
    registrarse,
    text5,
    freemium,
    price,
    x25MbAlmacenamiento,
    repositorio,
    address,
    address2,
    bsico,
    bsico2,
    text6,
    premium,
    text7,
    x50GbAlmacenamiento,
    text8,
    address3,
    address4,
    text9,
    premium2,
    text10,
    text11,
    address5,
    address6,
    text12,
    address7,
    text13,
    text14,
    text15,
    name,
    price2,
    price3,
    text16,
    text17,
    text18,
    image3,
    text19,
    text20,
    text21,
    pngegg11,
    image7,
    pngegg21,
    pngegg31,
  } = desktop1Data;
  const { t } = useTranslation();
  const { locale } = useLocalization();

  return (
    <Layout
      id="landing"
      locale={locale}
      bodyClass="page-home landing bg-grey trato-education"
      inverted
      border
      // simpleLayout
      location={location}
    >
      <div className="container-center-horizontal">
        <div className="desktop-1 screen">
          <OverlapGroup>
            <Ellipse6 src="/img/ellipse-6@2x.svg" />
            <Ellipse3 src="/img/ellipse-3@2x.svg" />
            <Ellipse7 src="/img/ellipse-7@2x.svg" />
            <Group4>
              <OverlapGroup3>
                <Vector src="/img/vector-29@2x.svg" />
                <Vector1 src="/img/vector-30@2x.svg" />
              </OverlapGroup3>
            </Group4>
            <Rectangle8></Rectangle8>
            <Image2 src={image2} />
            <Image4 src={image4} />
            <Group5>
              <OverlapGroup4>
                <Group3>
                  <OverlapGroup31>
                    <Group>
                      <OverlapGroup5>
                        <Vector2 src="/img/vector-31@2x.svg" />
                        <Vector3 src="/img/vector-32@2x.svg" />
                        <Vector4 src="/img/vector-33@2x.svg" />
                        <Vector5 src="/img/vector-34@2x.svg" />
                        <Vector6 src="/img/vector-35@2x.svg" />
                        <Vector7 src="/img/vector-36@2x.svg" />
                        <Vector8 src="/img/vector-37@2x.svg" />
                        <Vector9 src="/img/vector-38@2x.svg" />
                        <Vector10 src="/img/vector-39@2x.svg" />
                        <Vector11 src="/img/vector-40@2x.svg" />
                        <Vector12 src="/img/vector-41@2x.svg" />
                        <Vector13 src="/img/vector-53@2x.svg" />
                        <Vector14 src="/img/vector-42@2x.svg" />
                        <Group1>
                          <OverlapGroup7>
                            <Vector15 src="/img/vector-54@2x.svg" />
                            <Vector16 src="/img/vector-55@2x.svg" />
                          </OverlapGroup7>
                        </Group1>
                        <Group2></Group2>
                        <Vector17 src="/img/vector-43@2x.svg" />
                        <Vector18 src="/img/vector-44@2x.svg" />
                        <Vector19 src="/img/vector-57@2x.svg" />
                        <Vector20 src="/img/vector-58@2x.svg" />
                        <Vector21 src="/img/vector-59@2x.svg" />
                        <Vector22 src="/img/vector-60@2x.svg" />
                        <Vector23 src="/img/vector-61@2x.svg" />
                        <Vector24 src="/img/vector-62@2x.svg" />
                        <Vector25 src="/img/vector-45@2x.svg" />
                        <Vector26 src="/img/vector-63@2x.svg" />
                        <Vector27 src="/img/vector-46@2x.svg" />
                        <Vector28 src="/img/vector-64@2x.svg" />
                        <Vector29 src="/img/vector-47@2x.svg" />
                      </OverlapGroup5>
                      <Vector30 src="/img/vector-48@2x.svg" />
                      <Vector31 src="/img/vector-52@2x.svg" />
                      <OverlapGroup6>
                        <Vector32 src="/img/vector-49@2x.svg" />
                        <Vector33 src="/img/vector-50@2x.svg" />
                        <Vector34 src="/img/vector-51@2x.svg" />
                      </OverlapGroup6>
                    </Group>
                    <MaskGroup src="/img/mask-group-2@2x.svg" />
                  </OverlapGroup31>
                </Group3>
                <MaskGroup1 src="/img/mask-group-1@2x.svg" />
              </OverlapGroup4>
            </Group5>
            <Rectangle9/>
            <Text1>{text1}</Text1>
            <Text2>
              <span className="nunito-semi-bold-chathams-blue-25px">{spanText}</span>
              <span className="nunito-bold-chathams-blue-25px">{spanText2}</span>
              <span className="nunito-semi-bold-chathams-blue-25px">{spanText3}</span>
              <span className="nunito-bold-chathams-blue-25px">{spanText4}</span>
              <span className="nunito-semi-bold-chathams-blue-25px">{spanText5}</span>
            </Text2>
            <Text3>
              <span className="nunito-normal-chathams-blue-25px">{spanText6}</span>
              <span className="nunito-bold-chathams-blue-25px">{spanText7}</span>
              <span className="nunito-normal-chathams-blue-25px">{spanText8}</span>
              <span className="nunito-bold-chathams-blue-25px">{spanText9}</span>
              <span className="nunito-normal-chathams-blue-25px">{spanText10}</span>
              <span className="nunito-semi-bold-chathams-blue-25px">{spanText11}</span>
              <span className="nunito-normal-chathams-blue-25px">{spanText12}</span>
            </Text3>
            <Rectangle10/>
            <Rectangle11/>
            <Text4>{text4}</Text4>
            <a href="https://oyster.trato.io/signup" target="_blank" rel="noopener noreferrer"><Registrarse>{registrarse}</Registrarse></a>
          </OverlapGroup>
          <OverlapGroup1>
            <Group6>
              <OverlapGroup32>
                <Vector35 src="/img/vector@1x.svg" />
                <Vector36 src="/img/vector-1@2x.svg" />
                <Vector37 src="/img/vector-2@2x.svg" />
                <Vector38 src="/img/vector-3@2x.svg" />
                <Vector39 src="/img/vector-4@2x.svg" />
                <Vector40 src="/img/vector-5@2x.svg" />
                <Vector41 src="/img/vector-6@2x.svg" />
                <Vector42 src="/img/vector-7@2x.svg" />
                <Vector43 src="/img/vector-8@2x.svg" />
                <Vector44 src="/img/vector-9@2x.svg" />
                <Vector45 src="/img/vector-10@2x.svg" />
                <Vector46 src="/img/vector-17@1x.svg" />
                <Vector47 src="/img/vector-11@1x.svg" />
                <Group7>
                  <OverlapGroup41>
                    <Vector48 src="/img/vector-18@2x.svg" />
                    <Vector49 src="/img/vector-19@2x.svg" />
                  </OverlapGroup41>
                </Group7>
                <Vector50 src="/img/vector-20@1x.svg" />
                <Vector51 src="/img/vector-12@2x.svg" />
                <Vector52 src="/img/vector-13@2x.svg" />
                <Vector53 src="/img/vector-21@2x.svg" />
                <Vector54 src="/img/vector-22@2x.svg" />
                <Vector55 src="/img/vector-23@2x.svg" />
                <Vector56 src="/img/vector-24@2x.svg" />
                <Vector57 src="/img/vector-25@2x.svg" />
                <Vector58 src="/img/vector-26@2x.svg" />
                <Vector59 src="/img/vector-14@1x.svg" />
                <Vector60 src="/img/vector-27@2x.svg" />
                <Vector61 src="/img/vector-15@2x.svg" />
                <Vector62 src="/img/vector-28@2x.svg" />
                <Vector63 src="/img/vector-16@2x.svg" />
              </OverlapGroup32>
            </Group6>
            <Rectangle12 />
            <Rectangle21 />
            <Text5>{text5}</Text5>
            <Rectangle20 src="/img/rectangle-20-1@1x.svg" />
            <MaskGroup2 src="/img/mask-group@1x.svg" />
            <Vector64 src="/img/vector-135@2x.svg" />
            <Rectangle13 />
            <Freemium>{freemium}</Freemium>
            <Rectangle25 />
            <Price>{price}</Price>
            <X25MBAlmacenamiento>{x25MbAlmacenamiento}</X25MBAlmacenamiento>
            <Repositorio>{repositorio}</Repositorio>
            <Address>{address}</Address>
            <Address1>{address2}</Address1>
            <Rectangle15 />
            <Bsico>{bsico}</Bsico>
            <Group15>
              <OverlapGroup51>
                <Bsico1>{bsico2}</Bsico1>
                <Text6>{text6}</Text6>
              </OverlapGroup51>
            </Group15>
            <Group16>
              <OverlapGroup61>
                <Rectangle29 />
                <Bsico1>{premium}</Bsico1>
                <Text7>{text7}</Text7>
              </OverlapGroup61>
            </Group16>
            <X50GBAlmacenamiento>{x50GbAlmacenamiento}</X50GBAlmacenamiento>
            <Text8>{text8}</Text8>
            <Address2>{address3}</Address2>
            <Address3>{address4}</Address3>
            <Text9>{text9}</Text9>
            <Rectangle17 />
            <Premium>{premium2}</Premium>
            <Text10>{text10}</Text10>
            <Text11>{text11}</Text11>
            <Address4>{address5}</Address4>
            <Address5>{address6}</Address5>
            <Text12>{text12}</Text12>
            <Address6>{address7}</Address6>
            <Text13>{text13}</Text13>
            <Text14>{text14}</Text14>
            <Text15>{text15}</Text15>
            <Name>{name}</Name>
            <Rectangle22 />
            <Rectangle23 />
            <Rectangle24 />
            <Rectangle26 />
            <Rectangle27 />
            <Price1>{price2}</Price1>
            <Price2>{price3}</Price2>
            <Text16>{text16}</Text16>
            <Text17>{text17}</Text17>
            <Text18>{text18}</Text18>
            <Image3 src={image3} />
          </OverlapGroup1>
          <OverlapGroup2>
            <Text19>{text19}</Text19>
            <Text20>{text20}</Text20>
          </OverlapGroup2>
          <Group21>
            <OverlapGroup8>
              <MaskGroup3 src="/img/mask-group-7@1x.svg" />
              <Group17>
                <OverlapGroup62>
                  <OverlapGroup33>
                    <Text21>{text21}</Text21>
                    <Pngegg11 src={pngegg11} />
                  </OverlapGroup33>
                  <Asset101>
                    <OverlapGroup52>
                      <OverlapGroup42>
                        <Vector65 src="/img/vector-66@2x.svg" />
                        <Vector66 src="/img/vector-68@2x.svg" />
                      </OverlapGroup42>
                      <Vector67 src="/img/vector-67@2x.svg" />
                    </OverlapGroup52>
                    <Vector68 src="/img/vector-139@2x.svg" />
                  </Asset101>
                  <Image7 src={image7} />
                </OverlapGroup62>
                <FlexRow>
                  <GenommalabLogoMx011>
                    <OverlapGroup53>
                      <Group8 src="/img/group-3@2x.svg" />
                    </OverlapGroup53>
                  </GenommalabLogoMx011>
                  <Pngegg21 src={pngegg21} />
                  <Pngegg31 src={pngegg31} />
                </FlexRow>
              </Group17>
            </OverlapGroup8>
          </Group21>
        </div>
      </div>
    </Layout>
  );
}

OysterLanding.propTypes = {
  location: PropTypes.any,
};

export default OysterLanding;


const OverlapGroup = styled.div`
  width: 1440px;
  height: 1003px;
  position: relative;
`;

const Ellipse6 = styled.img`
  position: absolute;
  width: 357px;
  height: 357px;
  top: 107px;
  left: 953px;
`;

const Ellipse3 = styled.img`
  position: absolute;
  width: 279px;
  height: 279px;
  top: 569px;
  left: 835px;
`;

const Ellipse7 = styled.img`
  position: absolute;
  width: 313px;
  height: 313px;
  top: 56px;
  left: 859px;
`;

const Group4 = styled.div`
  position: absolute;
  height: 479px;
  top: 281px;
  left: 778px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

const OverlapGroup3 = styled.div`
  width: 715px;
  height: 660px;
  position: relative;
  margin-top: -86.02px;
`;

const Vector = styled.img`
  position: absolute;
  width: 662px;
  height: 607px;
  top: 0;
  left: 0;
`;

const Vector1 = styled.img`
  position: absolute;
  width: 662px;
  height: 609px;
  top: 51px;
  left: 53px;
`;

const Rectangle8 = styled.div`
  position: absolute;
  width: 1440px;
  height: 1003px;
  top: 0;
  left: 0;
  background-color: #f9f9f933;
  backdrop-filter: blur(4px) brightness(100%);
  -webkit-backdrop-filter: blur(4px) brightness(100%);
`;

const Image2 = styled.img`
  position: absolute;
  width: 378px;
  height: 287px;
  top: 398px;
  left: 750px;
  object-fit: cover;
`;

const Image4 = styled.img`
  position: absolute;
  width: 297px;
  height: 338px;
  top: 348px;
  left: 988px;
  object-fit: cover;
`;

const Group5 = styled.div`
  position: absolute;
  height: 122px;
  top: 159px;
  left: 813px;
  display: flex;
  align-items: flex-start;
`;

const OverlapGroup4 = styled.div`
  width: 548px;
  height: 122px;
  position: relative;
`;

const Group3 = styled.div`
  position: absolute;
  height: 122px;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
`;

const OverlapGroup31 = styled.div`
  width: 548px;
  height: 122px;
  position: relative;
  margin-top: -0.11px;
`;

const Group = styled.div`
  position: absolute;
  height: 77px;
  top: 28px;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const OverlapGroup5 = styled.div`
  width: 80px;
  height: 78px;
  position: relative;
  align-self: flex-start;
  margin-top: -0.7px;
`;

const Vector2 = styled.img`
  position: absolute;
  width: 76px;
  height: 76px;
  top: 1px;
  left: 2px;
`;

const Vector3 = styled.img`
  position: absolute;
  width: 36px;
  height: 45px;
  top: 30px;
  left: 39px;
`;

const Vector4 = styled.img`
  position: absolute;
  width: 42px;
  height: 45px;
  top: 30px;
  left: 5px;
`;

const Vector5 = styled.img`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 13px;
  left: 39px;
`;

const Vector6 = styled.img`
  position: absolute;
  width: 13px;
  height: 18px;
  top: 14px;
  left: 62px;
`;

const Vector7 = styled.img`
  position: absolute;
  width: 6px;
  height: 6px;
  top: 22px;
  left: 55px;
`;

const Vector8 = styled.img`
  position: absolute;
  width: 5px;
  height: 4px;
  top: 30px;
  left: 50px;
`;

const Vector9 = styled.img`
  position: absolute;
  width: 11px;
  height: 10px;
  top: 24px;
  left: 50px;
`;

const Vector10 = styled.img`
  position: absolute;
  width: 8px;
  height: 13px;
  top: 33px;
  left: 36px;
`;

const Vector11 = styled.img`
  position: absolute;
  width: 13px;
  height: 18px;
  top: 14px;
  left: 6px;
`;

const Vector12 = styled.img`
  position: absolute;
  width: 10px;
  height: 10px;
  top: 24px;
  left: 21px;
`;

const Vector13 = styled.img`
  position: absolute;
  width: 77px;
  height: 39px;
  top: 37px;
  left: 1px;
  mix-blend-mode: multiply;
`;

const Vector14 = styled.img`
  position: absolute;
  width: 77px;
  height: 78px;
  top: 0;
  left: 1px;
`;

const Group1 = styled.div`
  position: absolute;
  height: 24px;
  top: 3px;
  left: 10px;
  display: flex;
  align-items: flex-start;
  mix-blend-mode: multiply;
  opacity: 0.1;
`;

const OverlapGroup7 = styled.div`
  width: 62px;
  height: 25px;
  position: relative;
  margin-top: -0.3px;
`;

const Vector15 = styled.img`
  position: absolute;
  width: 32px;
  height: 25px;
  top: 0;
  left: 0;
  mix-blend-mode: multiply;
`;

const Vector16 = styled.img`
  position: absolute;
  width: 31px;
  height: 25px;
  top: 0;
  left: 31px;
  mix-blend-mode: multiply;
`;

const Group2 = styled.div`
  position: absolute;
  width: 80px;
  height: 29px;
  top: 19px;
  left: 0;
  mix-blend-mode: multiply;
  opacity: 0.1;
  background-image: url(/img/vector-56@2x.svg);
  background-size: 100% 100%;
`;

const Vector17 = styled.img`
  position: absolute;
  width: 31px;
  height: 25px;
  top: 2px;
  left: 40px;
`;

const Vector18 = styled.img`
  position: absolute;
  width: 32px;
  height: 25px;
  top: 2px;
  left: 9px;
`;

const Vector19 = styled.img`
  position: absolute;
  width: 30px;
  height: 25px;
  top: 20px;
  left: 5px;
`;

const Vector20 = styled.img`
  position: absolute;
  width: 19px;
  height: 20px;
  top: 20px;
  left: 17px;
`;

const Vector21 = styled.img`
  position: absolute;
  width: 15px;
  height: 13px;
  top: 21px;
  left: 6px;
`;

const Vector22 = styled.img`
  position: absolute;
  width: 31px;
  height: 25px;
  top: 20px;
  left: 44px;
`;

const Vector23 = styled.img`
  position: absolute;
  width: 17px;
  height: 21px;
  top: 19px;
  left: 58px;
`;

const Vector24 = styled.img`
  position: absolute;
  width: 14px;
  height: 15px;
  top: 21px;
  left: 46px;
`;

const Vector25 = styled.img`
  position: absolute;
  width: 80px;
  height: 30px;
  top: 17px;
  left: 0;
`;

const Vector26 = styled.img`
  position: absolute;
  width: 8px;
  height: 17px;
  top: 25px;
  left: 28px;
  mix-blend-mode: multiply;
`;

const Vector27 = styled.img`
  position: absolute;
  width: 3px;
  height: 3px;
  top: 22px;
  left: 76px;
`;

const Vector28 = styled.img`
  position: absolute;
  width: 8px;
  height: 17px;
  top: 25px;
  left: 44px;
  mix-blend-mode: multiply;
`;

const Vector29 = styled.img`
  position: absolute;
  width: 3px;
  height: 3px;
  top: 22px;
  left: 1px;
`;

const Vector30 = styled.img`
  width: 39px;
  height: 55px;
  margin-left: 9px;
  margin-top: 0.01px;
`;

const Vector31 = styled.img`
  width: 45px;
  height: 55px;
  margin-left: 4px;
  margin-bottom: 0.2px;
`;

const OverlapGroup6 = styled.div`
  width: 132px;
  height: 55px;
  position: relative;
  margin-left: 4px;
  margin-bottom: 0.39px;
`;

const Vector32 = styled.img`
  position: absolute;
  width: 47px;
  height: 55px;
  top: 0;
  left: 0;
`;

const Vector33 = styled.img`
  position: absolute;
  width: 39px;
  height: 55px;
  top: 0;
  left: 41px;
`;

const Vector34 = styled.img`
  position: absolute;
  width: 55px;
  height: 55px;
  top: 0;
  left: 77px;
`;

const MaskGroup = styled.img`
  position: absolute;
  width: 242px;
  height: 122px;
  top: 0;
  left: 306px;
`;

const MaskGroup1 = styled.img`
  position: absolute;
  width: 52px;
  height: 60px;
  top: 37px;
  left: 264px;
`;

const Rectangle9 = styled.div`
  position: absolute;
  width: 755px;
  height: 1003px;
  top: 0;
  left: 0;
  background-color: var(--hint-of-red);
`;

const Text1 = styled.div`
  position: absolute;
  width: 654px;
  top: 178px;
  left: 112px;
  font-family: var(--font-family-lato);
  font-weight: 900;
  color: var(--sherpa-blue);
  font-size: var(--font-size-l);
  letter-spacing: 0;
`;

const Text2 = styled.div`
  ${NunitoSemiBoldChathamsBlue25px}
  position: absolute;
  width: 628px;
  top: 379px;
  left: 112px;
  letter-spacing: 0;
`;

const Text3 = styled.div`
  ${NunitoNormalChathamsBlue25px}
  position: absolute;
  width: 1139px;
  top: 835px;
  left: 112px;
  letter-spacing: 0;
`;

const Rectangle10 = styled.div`
  position: absolute;
  width: 617px;
  height: 68px;
  top: 674px;
  left: 112px;
  background-color: var(--whisper);
  border-radius: 9px;
  box-shadow: 0px 4px 12px #dfe8f8;
`;

const Rectangle11 = styled.div`
  position: absolute;
  width: 150px;
  height: 68px;
  top: 674px;
  left: 579px;
  background-color: #4ac39d;
  border-radius: 9px;
  box-shadow: 0px 4px 12px #dfe8f8;
`;

const Text4 = styled.div`
  position: absolute;
  top: 697px;
  left: 130px;
  font-family: var(--font-family-lato);
  color: var(--sherpa-blue);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
`;

const Registrarse = styled.div`
  position: absolute;
  cursor: pointer;
  top: 697px;
  left: 603px;
  font-family: var(--font-family-nunito);
  font-weight: 700;
  color: var(--hint-of-red);
  font-size: var(--font-size-xxs);
  letter-spacing: 0;
`;

const OverlapGroup1 = styled.div`
  width: 1458px;
  height: 3022px;
  position: relative;
  margin-top: 8px;
  margin-left: -17.7px;
`;

const Group6 = styled.div`
  position: absolute;
  height: 626px;
  top: 2204px;
  left: 383px;
  display: flex;
  align-items: flex-start;
`;

const OverlapGroup32 = styled.div`
  width: 654px;
  height: 626px;
  position: relative;
  margin-top: -0.1px;
`;

const Vector35 = styled.img`
  position: absolute;
  width: 614px;
  height: 614px;
  top: 6px;
  left: 18px;
`;

const Vector36 = styled.img`
  position: absolute;
  width: 291px;
  height: 355px;
  top: 247px;
  left: 321px;
`;

const Vector37 = styled.img`
  position: absolute;
  width: 337px;
  height: 355px;
  top: 246px;
  left: 45px;
`;

const Vector38 = styled.img`
  position: absolute;
  width: 255px;
  height: 254px;
  top: 107px;
  left: 322px;
`;

const Vector39 = styled.img`
  position: absolute;
  width: 99px;
  height: 137px;
  top: 113px;
  left: 506px;
`;

const Vector40 = styled.img`
  position: absolute;
  width: 44px;
  height: 44px;
  top: 180px;
  left: 451px;
`;

const Vector41 = styled.img`
  position: absolute;
  width: 27px;
  height: 27px;
  top: 245px;
  left: 415px;
`;

const Vector42 = styled.img`
  position: absolute;
  width: 75px;
  height: 76px;
  top: 195px;
  left: 416px;
`;

const Vector43 = styled.img`
  position: absolute;
  width: 54px;
  height: 101px;
  top: 269px;
  left: 301px;
`;

const Vector44 = styled.img`
  position: absolute;
  width: 98px;
  height: 135px;
  top: 115px;
  left: 54px;
`;

const Vector45 = styled.img`
  position: absolute;
  width: 76px;
  height: 76px;
  top: 195px;
  left: 176px;
`;

const Vector46 = styled.img`
  position: absolute;
  width: 627px;
  height: 314px;
  top: 302px;
  left: 11px;
  mix-blend-mode: multiply;
`;

const Vector47 = styled.img`
  position: absolute;
  width: 627px;
  height: 626px;
  top: 0;
  left: 11px;
`;

const Group7 = styled.div`
  position: absolute;
  height: 200px;
  top: 19px;
  left: 78px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  mix-blend-mode: multiply;
  opacity: 0.1;
`;

const OverlapGroup41 = styled.div`
  width: 501px;
  height: 201px;
  position: relative;
  margin-top: -0.62px;
`;

const Vector48 = styled.img`
  position: absolute;
  width: 251px;
  height: 200px;
  top: 0;
  left: 0;
  mix-blend-mode: multiply;
`;

const Vector49 = styled.img`
  position: absolute;
  width: 251px;
  height: 201px;
  top: 0;
  left: 250px;
  mix-blend-mode: multiply;
`;

const Vector50 = styled.img`
  position: absolute;
  width: 652px;
  height: 236px;
  top: 150px;
  left: 2px;
  mix-blend-mode: multiply;
`;

const Vector51 = styled.img`
  position: absolute;
  width: 251px;
  height: 201px;
  top: 14px;
  left: 328px;
`;

const Vector52 = styled.img`
  position: absolute;
  width: 251px;
  height: 200px;
  top: 14px;
  left: 78px;
`;

const Vector53 = styled.img`
  position: absolute;
  width: 243px;
  height: 198px;
  top: 159px;
  left: 43px;
`;

const Vector54 = styled.img`
  position: absolute;
  width: 148px;
  height: 156px;
  top: 159px;
  left: 138px;
`;

const Vector55 = styled.img`
  position: absolute;
  width: 119px;
  height: 101px;
  top: 169px;
  left: 51px;
`;

const Vector56 = styled.img`
  position: absolute;
  width: 242px;
  height: 198px;
  top: 159px;
  left: 368px;
`;

const Vector57 = styled.img`
  position: absolute;
  width: 134px;
  height: 162px;
  top: 158px;
  left: 476px;
`;

const Vector58 = styled.img`
  position: absolute;
  width: 113px;
  height: 115px;
  top: 167px;
  left: 378px;
`;

const Vector59 = styled.img`
  position: absolute;
  width: 653px;
  height: 236px;
  top: 140px;
  left: 0;
`;

const Vector60 = styled.img`
  position: absolute;
  width: 58px;
  height: 133px;
  top: 202px;
  left: 234px;
  mix-blend-mode: multiply;
`;

const Vector61 = styled.img`
  position: absolute;
  width: 16px;
  height: 15px;
  top: 179px;
  left: 623px;
`;

const Vector62 = styled.img`
  position: absolute;
  width: 58px;
  height: 133px;
  top: 203px;
  left: 362px;
  mix-blend-mode: multiply;
`;

const Vector63 = styled.img`
  position: absolute;
  width: 16px;
  height: 15px;
  top: 177px;
  left: 10px;
`;

const Rectangle12 = styled.div`
  position: absolute;
  width: 1440px;
  height: 1010px;
  top: 0;
  left: 18px;
  background-color: var(--black-haze);
`;

const Rectangle21 = styled.div`
  position: absolute;
  width: 1440px;
  height: 1470px;
  top: 1552px;
  left: 18px;
  background-color: #344e5be6;
`;

const Text5 = styled.div`
  position: absolute;
  width: 1129px;
  top: 74px;
  left: 174px;
  font-family: var(--font-family-lato);
  font-weight: 900;
  color: var(--chathams-blue);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
`;

const Rectangle20 = styled.img`
  position: absolute;
  width: 1440px;
  height: 1635px;
  top: 233px;
  left: 18px;
`;

const MaskGroup2 = styled.img`
  position: absolute;
  width: 1440px;
  height: 1627px;
  top: 241px;
  left: 18px;
`;

const Vector64 = styled.img`
  position: absolute;
  width: 36px;
  height: 82px;
  top: 454px;
  left: 0;
`;

const Rectangle13 = styled.div`
  position: absolute;
  width: 366px;
  height: 620px;
  top: 254px;
  left: 98px;
  background-color: #014651b2;
  border-radius: 7px;
`;

const Freemium = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  width: 313px;
  height: 59px;
  top: 254px;
  left: 134px;
  font-family: var(--font-family-lato);
  font-weight: 300;
  color: var(--black-haze);
  font-size: var(--font-size-m);
  letter-spacing: 0;
`;

const Rectangle25 = styled.div`
  position: absolute;
  width: 111px;
  height: 56px;
  top: 475px;
  left: 226px;
  background-color: #007b8f;
  border-radius: 3px;
`;

const Price = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  height: 48px;
  top: 479px;
  left: 260px;
  font-family: var(--font-family-nunito);
  font-weight: 600;
  color: var(--black-haze);
  font-size: 35px;
  text-align: center;
  letter-spacing: 0;
`;

const X25MBAlmacenamiento = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalBlackHaze20px}
            position: absolute;
  height: 24px;
  top: 571px;
  left: 119px;
  letter-spacing: 0;
`;

const Repositorio = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalBlackHaze20px}
            position: absolute;
  height: 24px;
  top: 612px;
  left: 119px;
  letter-spacing: 0;
`;

const Address = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalBlackHaze20px}
            position: absolute;
  height: 24px;
  top: 653px;
  left: 119px;
  letter-spacing: 0;
`;

const Address1 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalBlackHaze20px}
            position: absolute;
  height: 24px;
  top: 694px;
  left: 119px;
  letter-spacing: 0;
`;

const Rectangle15 = styled.div`
  position: absolute;
  width: 366px;
  height: 620px;
  top: 254px;
  left: 558px;
  background-color: #5fa0d6b2;
  border-radius: 7px;
`;

const Bsico = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  width: 314px;
  height: 59px;
  top: 254px;
  left: 600px;
  font-family: var(--font-family-lato);
  font-weight: 300;
  color: var(--black);
  font-size: var(--font-size-m);
  letter-spacing: 0;
`;

const Group15 = styled.div`
  position: absolute;
  height: 740px;
  top: 1110px;
  left: 105px;
  display: flex;
  align-items: flex-start;
`;

const OverlapGroup51 = styled.div`
  width: 566px;
  height: 740px;
  position: relative;
  background-color: var(--black-haze);
  border-radius: 7px;
`;

const Bsico1 = styled.div`
  ${ValignTextMiddle}
  ${LatoBoldBlack35px}
            position: absolute;
  width: 314px;
  height: 59px;
  top: 31px;
  left: 32px;
  letter-spacing: 0;
`;

const Text6 = styled.div`
  ${ValignTextBottom}
  ${LatoLightBlack30px}
            position: absolute;
  width: 499px;
  height: 658px;
  top: 56px;
  left: 34px;
  letter-spacing: 0;
`;

const Group16 = styled.div`
  position: absolute;
  height: 884px;
  top: 1110px;
  left: 809px;
  display: flex;
  align-items: flex-start;
`;

const OverlapGroup61 = styled.div`
  width: 566px;
  height: 884px;
  position: relative;
`;

const Rectangle29 = styled.div`
  position: absolute;
  width: 566px;
  height: 877px;
  top: 0;
  left: 0;
  background-color: var(--black-haze);
  border-radius: 7px;
`;

const Text7 = styled.div`
  ${ValignTextBottom}
  ${LatoLightBlack30px}
            position: absolute;
  width: 499px;
  height: 721px;
  top: 115px;
  left: 34px;
  letter-spacing: 0;
`;

const X50GBAlmacenamiento = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalBlack20px}
            position: absolute;
  height: 24px;
  top: 571px;
  left: 583px;
  letter-spacing: 0;
`;

const Text8 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalBlack20px}
            position: absolute;
  height: 24px;
  top: 612px;
  left: 583px;
  letter-spacing: 0;
`;

const Address2 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalBlack20px}
            position: absolute;
  height: 24px;
  top: 653px;
  left: 583px;
  letter-spacing: 0;
`;

const Address3 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalBlack20px}
            position: absolute;
  height: 24px;
  top: 694px;
  left: 583px;
  letter-spacing: 0;
`;

const Text9 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalBlack20px}
            position: absolute;
  height: 24px;
  top: 735px;
  left: 583px;
  letter-spacing: 0;
`;

const Rectangle17 = styled.div`
  position: absolute;
  width: 366px;
  height: 620px;
  top: 254px;
  left: 1009px;
  background-color: #1fd7ae99;
  border-radius: 7px;
`;

const Premium = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  width: 314px;
  height: 59px;
  top: 254px;
  left: 1051px;
  font-family: var(--font-family-lato);
  font-weight: 300;
  color: var(--chathams-blue);
  font-size: var(--font-size-m);
  letter-spacing: 0;
`;

const Text10 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalChathamsBlue20px}
            position: absolute;
  height: 24px;
  top: 571px;
  left: 1053px;
  letter-spacing: 0;
`;

const Text11 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalChathamsBlue20px}
            position: absolute;
  height: 24px;
  top: 612px;
  left: 1053px;
  letter-spacing: 0;
`;

const Address4 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalChathamsBlue20px}
            position: absolute;
  height: 24px;
  top: 653px;
  left: 1053px;
  letter-spacing: 0;
`;

const Address5 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalChathamsBlue20px}
            position: absolute;
  height: 24px;
  top: 694px;
  left: 1053px;
  letter-spacing: 0;
`;

const Text12 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalChathamsBlue20px}
            position: absolute;
  height: 24px;
  top: 735px;
  left: 1053px;
  letter-spacing: 0;
`;

const Address6 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalChathamsBlue20px}
            position: absolute;
  height: 24px;
  top: 776px;
  left: 1053px;
  letter-spacing: 0;
`;

const Text13 = styled.div`
  ${ValignTextMiddle}
  ${LatoNormalChathamsBlue20px}
            position: absolute;
  height: 24px;
  top: 817px;
  left: 1053px;
  letter-spacing: 0;
`;

const Text14 = styled.div`
  position: absolute;
  width: 292px;
  top: 348px;
  left: 147px;
  font-family: var(--font-family-nunito);
  font-weight: 600;
  color: var(--white);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
`;

const Text15 = styled.div`
  position: absolute;
  width: 292px;
  top: 353px;
  left: 613px;
  font-family: var(--font-family-nunito);
  font-weight: 600;
  color: var(--black);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
`;

const Name = styled.div`
  position: absolute;
  width: 292px;
  top: 358px;
  left: 1063px;
  font-family: var(--font-family-nunito);
  font-weight: 600;
  color: var(--chathams-blue);
  font-size: var(--font-size-xs);
  letter-spacing: 0;
`;

const Rectangle22 = styled.div`
  position: absolute;
  width: 3px;
  height: 74px;
  top: 359px;
  left: 134px;
  background-color: var(--white);
  border-radius: 15px;
`;

const Rectangle23 = styled.div`
  position: absolute;
  width: 3px;
  height: 74px;
  top: 359px;
  left: 600px;
  background-color: #0d151c;
  border-radius: 15px;
`;

const Rectangle24 = styled.div`
  position: absolute;
  width: 3px;
  height: 74px;
  top: 359px;
  left: 1051px;
  background-color: #0e577b;
  border-radius: 15px;
`;

const Rectangle26 = styled.div`
  position: absolute;
  width: 111px;
  height: 56px;
  top: 475px;
  left: 685px;
  background-color: #1fd7ae;
  border-radius: 3px;
`;

const Rectangle27 = styled.div`
  position: absolute;
  width: 111px;
  height: 56px;
  top: 475px;
  left: 1136px;
  background-color: var(--black-haze);
  border-radius: 3px;
`;

const Price1 = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  height: 48px;
  top: 479px;
  left: 709px;
  font-family: var(--font-family-nunito);
  font-weight: 600;
  color: var(--black);
  font-size: 35px;
  text-align: center;
  letter-spacing: 0;
`;

const Price2 = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  height: 48px;
  top: 479px;
  left: 1150px;
  font-family: var(--font-family-nunito);
  font-weight: 600;
  color: var(--chathams-blue);
  font-size: 35px;
  text-align: center;
  letter-spacing: 0;
`;

const Text16 = styled.h1`
  ${ValignTextMiddle}
  position: absolute;
  width: 1267px;
  height: 95px;
  top: 1001px;
  left: 110px;
  font-family: var(--font-family-lato);
  font-weight: 900;
  color: var(--chathams-blue);
  font-size: var(--font-size-xxl);
  letter-spacing: 0;
`;

const Text17 = styled.div`
  ${ValignTextMiddle}
  ${LatoBlackWhite55px}
            position: absolute;
  width: 1001px;
  height: 135px;
  top: 2021px;
  left: 110px;
  letter-spacing: 0;
`;

const Text18 = styled.div`
  position: absolute;
  width: 565px;
  top: 2189px;
  left: 94px;
  font-family: var(--font-family-lato);
  color: var(--white);
  font-size: var(--font-size-m);
  letter-spacing: 0;
`;

const Image3 = styled.img`
  position: absolute;
  width: 537px;
  height: 611px;
  top: 2243px;
  left: 745px;
  object-fit: cover;
`;

const OverlapGroup2 = styled.div`
  width: 1440px;
  display: flex;
  flex-direction: column;
  padding: 18px 87px;
  align-items: flex-start;
  min-height: 438px;
  background-color: var(--deep-space-sparkle);
`;

const Text19 = styled.div`
  ${ValignTextMiddle}
  ${LatoBlackWhite55px}
            width: 970px;
  height: 135px;
  margin-left: 5px;
  letter-spacing: 0;
`;

const Text20 = styled.div`
  width: 1138px;
  min-height: 187px;
  margin-top: 10px;
  font-family: var(--font-family-lato);
  color: #f8f9fb;
  font-size: var(--font-size-m);
  letter-spacing: 0;
`;

const Group21 = styled.div`
  margin-top: 2px;
  display: flex;
  align-items: flex-start;
  min-width: 1440px;
`;

const OverlapGroup8 = styled.div`
  width: 1440px;
  height: 572px;
  position: relative;
`;

const MaskGroup3 = styled.img`
  position: absolute;
  width: 1440px;
  height: 571px;
  top: 0;
  left: 0;
`;

const Group17 = styled.div`
  position: absolute;
  width: 1286px;
  top: 0;
  left: 87px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const OverlapGroup62 = styled.div`
  width: 1286px;
  height: 327px;
  position: relative;
`;

const OverlapGroup33 = styled.div`
  position: absolute;
  width: 1283px;
  height: 327px;
  top: 0;
  left: 3px;
`;

const Text21 = styled.div`
  ${ValignTextMiddle}
  position: absolute;
  width: 1283px;
  height: 135px;
  top: 0;
  left: 0;
  font-family: var(--font-family-lato);
  font-weight: 900;
  color: var(--deep-space-sparkle);
  font-size: var(--font-size-xl);
  letter-spacing: 0;
`;

const Pngegg11 = styled.img`
  position: absolute;
  width: 253px;
  height: 193px;
  top: 134px;
  left: 880px;
  object-fit: cover;
`;

const Asset101 = styled.div`
  position: absolute;
  width: 160px;
  height: 125px;
  top: 158px;
  left: 517px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
`;

const OverlapGroup52 = styled.div`
  width: 160px;
  height: 69px;
  position: relative;
  margin-left: -0.14px;
`;

const OverlapGroup42 = styled.div`
  position: absolute;
  width: 159px;
  height: 68px;
  top: 1px;
  left: 0;
`;

const Vector65 = styled.img`
  position: absolute;
  width: 54px;
  height: 64px;
  top: 0;
  left: 0;
`;

const Vector66 = styled.img`
  position: absolute;
  width: 120px;
  height: 54px;
  top: 14px;
  left: 39px;
`;

const Vector67 = styled.img`
  position: absolute;
  width: 15px;
  height: 15px;
  top: 0;
  left: 145px;
`;

const Vector68 = styled.img`
  width: 160px;
  height: 51px;
  margin-top: 5px;
`;

const Image7 = styled.img`
  position: absolute;
  width: 290px;
  height: 59px;
  top: 191px;
  left: 0;
  object-fit: cover;
`;

const FlexRow = styled.div`
  height: 115px;
  display: flex;
  align-items: flex-end;
  min-width: 1120px;
  margin-top: 12px;
`;

const GenommalabLogoMx011 = styled.div`
  width: 290px;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
`;

const OverlapGroup53 = styled.div`
  margin-bottom: -0.06px;
  display: flex;
  align-items: flex-start;
  min-width: 289px;
  background-image: url(/img/group-2@2x.svg);
  background-size: 100% 100%;
`;

const Group8 = styled.img`
  width: 289px;
  height: 78px;
`;

const Pngegg21 = styled.img`
  width: 168px;
  height: 73px;
  margin-left: 223px;
  margin-bottom: 10px;
  object-fit: cover;
`;

const Pngegg31 = styled.img`
  width: 220px;
  height: 110px;
  align-self: flex-start;
  margin-left: 219px;
  object-fit: cover;
`;